<template>
  <v-dialog
    v-model="showModal"
    max-width="500"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-cash-plus
        </v-icon> Add Cash Flow
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class=" text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="job"
                  class="field-style"
                  :items="JobList"
                  label="Job"
                  single-line
                  item-value="id"
                  item-text="name"
                  :rules="[(v) => !!v || 'This field is required']"
                  return-object
                  outlined
                  dense
                  @select="clearDates()"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="type"
                  class="field-style"
                  :items="items"
                  label="Type"
                  single-line
                  item-value="id"
                  item-text="name"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="clientName"
                  class="field-style"
                  label="Client Name"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="clientAmount"
                  class="field-style"
                  label="Client Amount"
                  :rules="[(v) => !!v || 'This field is required']"
                  type="number"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="paymentDateDialog"
                  v-model="paymentDateDialog"
                  :return-value.sync="paymentDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="paymentDate"
                      label="Payment Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      outlined
                      :rules="[(v) => !!v || 'This field is required']"
                      dense
                      v-bind="attrs"
                      class="field-style"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="savePaymentdate"
                    scrollable
                    color="#37474F"
                    :min="minDate"
                    :max="maxDate"
                    @input="$refs.paymentDateDialog.save(savePaymentdate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="paymentDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-dialog
                  ref="dueDateDialog"
                  v-model="dueDateDialog"
                  :return-value.sync="dueDate"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dueDate"
                      label="Due Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :rules="[(v) => !!v || 'This field is required']"
                      outlined
                      dense
                      v-bind="attrs"
                      class="field-style"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="saveDueDate"
                    scrollable
                    color="#37474F"
                    :min="minDate"
                    @input="$refs.dueDateDialog.save(saveDueDate)"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="dueDateDialog = false"
                    >
                      Cancel
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#000"
              @click="create()"
            >
              Create
            </v-btn>
            <v-btn
              color="#ccc"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import Constants from 'src/constants';
export default {
  name: 'AddCashFlow',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      job: null,
      clientName: null,
      clientAmount: null,
      dueDate: null,
      isFormValid: false,
      paymentDate: null,
      saveDueDate: null,
      paymentDateDialog: null,
      dueDateDialog: null,
      loading: false,
      savePaymentdate: null,
      type: null,
      items: [{ name: 'Initial Payment', id: 'initial-payment' }, { name: 'Hand Over Payment', id: 'hand-over-payment' }, { name: 'Milestone Payment', id: 'milestone-payment' }],
    };
  },
  computed: {
    JobList () {
      const data = this.$store.getters['jobs/getMilestonesList'];
      return data.data;
    },
    minDate () {
      if (this.job !== null) {
        return moment(this.job.planned_start_date).format('YYYY-MM-DD');
      } else {
        return moment().format();
      }
    },
    maxDate () {
      if (this.job !== null) {
        return moment(this.job.planned_end_date).format('YYYY-MM-DD');
      } else {
        return moment().format();
      }
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    await this.getJobList();
  },
  methods: {
    async getJobList () {
      this.loading = true;
      await this.$store.dispatch('jobs/fetchMilestonesByProject', { projectId: this.$route.params.projectId, params: { params: { jobType: 'milestone' } } }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async create () {
      if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
      this.loading = true;
      await this.$store.dispatch('cashflow/addCashFlow', {
        projectId: this.$route.params.projectId,
        details: {
          job_id: this.job.id,
          client_name: this.clientName,
          client_amount: this.clientAmount,
          payment_date: this.paymentDate,
          due_date: this.dueDate,
          type: this.type,
        },
      }).then(() => {
        this.loading = true;
        this.$store.dispatch('cashflow/fetchCashFlows', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.$emit('closed');
        this.loading = false;
      });
      this.loading = false;
    },
    clearDates () {
      this.paymentDate = null;
      this.dueDate = null;
      this.savePaymentdate = null;
      this.saveDueDate = null;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
  margin-bottom: 10px;
}

.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}

.my-container-style {
  padding-top: 24px;
}

.hrw-switch {
  margin-top: -20px;
}

.field-style {
  margin-top: -10px;
}

.v-card__actions {
  padding: 16px;
  padding-top: 0px !important;
}
</style>
