<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
    class="pl-0 pr-0"
  >
    <v-card
      color="#fff"
      class="project-list-card mt-0"
    >
      <br>
      <v-tabs v-model="jobsTabs">
        <v-tab v-if="isJobSchedulesExist">
          Schedule
        </v-tab>
        <v-tab v-if="isJobCashflowExist">
          Cash flow
        </v-tab>
        <v-tab v-if="isJobSchedulesExist">
          Requests
        </v-tab>
        <v-tab>
          Invoices
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="jobsTabs">
        <v-tab-item>
          <div>
            <div class="d-flex float-left pt-3">
              <v-select
                v-if="publicStatus"
                v-model="versionDetails"
                :items="versionLists"
                item-text="version"
                label="Versions"
                outlined
                dense
                return-object
                class="version-select"
                @input="changeVersions"
              />
              <v-btn
                v-if="publicStatus"
                color="red"
                class="ml-2"
                @click="clearVersion"
              >
                Clear
              </v-btn>
            </div>
          </div>
          <div class="d-flex align-center justify-end">
            <span class="mr-2">Draft</span>
            <v-switch
              v-model="scheduleStatus"
              inset
              color="green"
            />
            <span>Published</span>
          </div>
          <v-col class="text-right table-top-border">
            <v-chip v-if="!publicStatus">
              Draft
            </v-chip>
            <v-btn
              class="ma-2"
              outlined
              color="teal"
              icon
              @click="showGanttView()"
            >
              <v-icon>mdi-chart-gantt</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="teal"
              icon
              @click="showListView()"
            >
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
            <v-btn
              v-if="!publicStatus"
              color="#37474f"
              :disabled="!isChanged"
              @click="saveChanges()"
            >
              <v-icon class="mr-2">
                mdi-content-save-outline
              </v-icon>
              Save
            </v-btn>
            <v-btn
              v-if="!publicStatus"
              color="#000"
              class="ml-2"
              @click="showJobCreateDialog = true"
            >
              <v-icon class="mr-2">
                mdi-plus-circle-outline
              </v-icon>
              Create Job
            </v-btn>
            <v-btn
              v-if="!publicStatus"
              color="green"
              class="ml-3"
              :disabled="!isSaved || isPublished"
              @click="publishSchedule()"
            >
              <v-icon>mdi-earth</v-icon> Publish
            </v-btn>
            <v-chip
              v-else
              color="green"
              outlined
            >
              <v-icon>mdi-earth</v-icon>Published
            </v-chip>
            <v-btn
              v-if="publicStatus && isPublishedScheduleExists !== 0"
              color="#000"
              class="ml-3"
              @click="showScheduleTemplate = true"
            >
              Save as Template
            </v-btn>
          </v-col>
          <div
            v-show="GanttChart"
            v-if="jobList.length !== 0"
            id="gantt_here"
          />
          <div
            v-else
            class="d-flex justify-center align-center"
          >
            <v-icon>mdi-chart-gantt</v-icon>
            <h2 class="mb-0">
              There is no schedule to list.
            </h2>
          </div>
          <div
            v-show="ListView"
            v-if="jobList.length !== 0"
          >
            <job-list
              :refreshlist="refreshList"
              :public-status="publicStatus"
              @job-details="showEditJob"
              @update:refreshlist="refreshList = $event"
              @enableSave="onGanttChartChange"
            />
          </div>
          <div
            v-else
            class="d-flex justify-center align-center"
          >
            <v-icon>mdi-format-list-bulleted-square</v-icon>
            <h2 class="mb-0">
              There is no jobs to list.
            </h2>
          </div>
          <div class="d-flex align-center justify-center">
            <div>
              <v-btn
                v-if="jobList.length === 0 && !publicStatus"
                color="#6a6fe0"
                outlined
                class="mt-5"
                @click="showImportTemplate = true"
              >
                Import Template
              </v-btn>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <cash-flow-nav-drawer />
        </v-tab-item>
        <v-tab-item>
          <request-list />
        </v-tab-item>
        <v-tab-item>
          <invoice-list />
        </v-tab-item>
      </v-tabs-items>
      <br>
    </v-card>
    <centre-spinner :loading="loading" />
    <budget-details
      v-if="showBudgetDetails"
      :budget-id="budgetId"
      @closed="showBudgetDetails = false"
      @update-budget="editBudgetDetails"
    />
    <edit-budget-details
      v-if="showEditDialog"
      :budget-id="budgetId"
      @closed="showEditDialog = false"
      @budget-details-updated="getProjectBudgets"
    />
    <create-job
      v-if="showJobCreateDialog"
      @closed="showJobCreateDialog = false"
      @success="handleJobSuccess"
    />
    <edit-job
      v-if="showEditJobDialog"
      :job-id="jobId"
      :job-types="jobTypes"
      @closed="showEditJobDialog = false"
      @success="handleJobSuccess"
    />
    <send-notification
      v-if="showPublishSchedule"
      @closed="showPublishSchedule = false"
    />
    <create-schedule-template
      v-if="showScheduleTemplate"
      @closed="showScheduleTemplate = false"
    />
    <import-schedule-template
      v-if="showImportTemplate"
      @closed="showImportTemplate = false"
      @success="handleImportSuccess"
    />
  </v-container>
</template>

<script>
import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import BudgetDetails from './BudgetDetails.vue';
import EditBudgetDetails from '../budgets/EditBudgetDetails.vue';
import Constants from 'src/constants';
import CreateJob from './CreateJob.vue';
import EditJob from './EditJob.vue';
import ModuleHelper from 'src/helpers/module-helper';
import JobsList from './JobsList.vue';
import RequestsList from './RequestsList.vue';
import InvoiceLists from './InvoicesList.vue';
import SendNotification from './SendNotification.vue';
import CashFlowNavDrawer from './cashflow/CashFlowNavDrawer.vue';
import createScheduleTemplate from './createScheduleTemplate.vue';
import ImportScheduleTemplate from './ImportScheduleTemplate.vue';
export default {
  name: 'ProjectSchedulingChart',
  components: {
    'centre-spinner': spinner,
    'budget-details': BudgetDetails,
    'edit-budget-details': EditBudgetDetails,
    'create-job': CreateJob,
    'edit-job': EditJob,
    'job-list': JobsList,
    'request-list': RequestsList,
    'cash-flow-nav-drawer': CashFlowNavDrawer,
    'invoice-list': InvoiceLists,
    'send-notification': SendNotification,
    'create-schedule-template': createScheduleTemplate,
    'import-schedule-template': ImportScheduleTemplate,
  },
  data () {
    return {
      tasks: {
        data: [],
        links: [],
      },
      coloumns: [
        { name: 'wbs', label: 'WBS', min_width: 50, width: 50, template: gantt.getWBSCode, resize: true },
        { name: 'text', label: 'Task name', tree: true, min_width: 180, width: 200, resize: true },
        { name: 'start_date', label: 'Start', align: 'center', width: 100, resize: true },
        {
          name: 'end_date',
          label: 'End',
          align: 'center',
          width: 100,
          resize: true,
        },
        { name: 'duration', align: 'center', width: 80, resize: true },
      ],
      loading: false,
      showBudgetDetails: false,
      jobId: '',
      showEditDialog: false,
      jobsTabs: null,
      GanttChart: true,
      ListView: false,
      showJobCreateDialog: false,
      showEditJobDialog: false,
      showPublishSchedule: false,
      helper: new ModuleHelper(),
      refreshList: false,
      publicStatus: false,
      savedStatus: false,
      jobType: '',
      versionDetails: null,
      showScheduleTemplate: false,
      scheduleStatus: false,
      showImportTemplate: false,
      jobTypes: '',
      isChanged: false,
      isSaved: false,
      isPublished: false,
    };
  },
  computed: {
    projectDetails () {
      return this.$store.getters['projects/getProjectDetails'];
    },
    jobList () {
      const list = this.$store.getters['jobs/getJobList'];
      return list;
    },
    publishedJobList () {
      const list = this.$store.getters['jobs/getPublishedJobList'];
      return list;
    },
    isJobSchedulesExist () {
      return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS, Constants.SUB_MODULE_JOBS_SCHEDULE));
    },
    isJobCashflowExist () {
      return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS, Constants.SUB_MODULE_JOBS_CASHFLOW));
    },
    ProjectScheduleLinks () {
      const scheduleLinks = this.$store.getters['projects/getProjectScheduleLinks'];
      return scheduleLinks;
    },
    ProjectPublicScheduleLinks () {
      const scheduleLinks = this.$store.getters['projects/getProjectSchedulePublicLinks'];
      return scheduleLinks;
    },
    isSchedulingExist () {
      return this.ProjectScheduleLinks.length > 0;
    },
    showNoBudgetAlert () {
      if (this.jobList.length === 0) {
        return true;
      }
      return false;
    },
    isPublishedStatus () {
      return this.$store.getters['jobs/getEnablePublish'];
    },
    isPublishedScheduleExists () {
      const list = this.$store.getters['jobs/getPublishedJobList'];
      return list.length;
    },
    versionLists () {
      return this.$store.getters['jobs/getScheduleVersions'];
    },
    enablePublished () {
      return this.publishButton;
    },
    enableSaved () {
      return this.$store.getters['jobs/getSaveBtn'];
    },
  },
  watch: {
    GanttChart (value) {
      this.GanttChart = value;
    },
    scheduleStatus (value) {
      if (value) {
        this.showPublicSchedule();
      } else {
        this.showDraftSchedule();
      }
    },
  },
  created () {
    this.isChanged = JSON.parse(localStorage.getItem('isChanged')) || false;
    this.isSaved = JSON.parse(localStorage.getItem('isSaved')) || false;
    this.isPublished = JSON.parse(localStorage.getItem('isPublished')) || false;
  },
  async mounted () {
    await this.getJobList();
    await this.getJobListPublic();
    await this.getScheduleVersionsList();
  },
  methods: {
    async handleImportSuccess () {
      await this.getJobList();
      this.onGanttChartChange();
    },
    async handleJobSuccess () {
      await this.getJobList();
      this.onGanttChartChange();
    },
    onGanttChartChange () {
      this.isChanged = true;
      this.isSaved = false;
      this.isPublished = false;
      localStorage.setItem('isChanged', true);
      localStorage.setItem('isSaved', false);
      localStorage.setItem('isPublished', false);
    },
    async getScheduleTemplates () {
      await this.$store.dispatch('jobs/fetchScheduleTemplates');
    },
    async clearVersion () {
      await this.getJobListPublic();
      this.versionDetails = null;
    },
    async showDraftSchedule () {
      gantt.clearAll();
      gantt.config.readonly = false;
      gantt.config.drag_links = true;
      this.publicStatus = false;
      await this.getJobList();
    },
    async showPublicSchedule () {
      gantt.clearAll();
      gantt.config.readonly = true;
      gantt.config.drag_links = false;
      this.publicStatus = true;
      await this.getJobListPublic();
      await this.getScheduleTemplates();
    },
    showEditJob (data) {
      this.jobId = data.jobId;
      this.jobTypes = data.jobType;
      this.showEditJobDialog = true;
    },
    showGanttView () {
      this.GanttChart = true;
      this.ListView = false;
    },
    showListView () {
      this.GanttChart = false;
      this.ListView = true;
    },
    async getJobList () {
      this.refreshList = true;
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('jobs/fetchJobsByProject', { projectid: this.$route.params.projectId, params: { params: { orderByPlannedStartDate: 'asc' } } }).then(response => {
        this.getBudgetsScheduleLinks();
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getJobListPublic () {
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('jobs/fetchPublicJobsByProject', { projectid: this.$route.params.projectId, params: { params: { orderByPlannedStartDate: 'asc' } } }).then(response => {
        this.getBudgetsPublicScheduleLinks();
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getBudgetsScheduleLinks () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchBudgetsScheduleLinks', {
        projectId: this.$route.params.projectId,
      }).then(response => {
        if (this.isJobSchedulesExist) {
          this.initGantt();
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getProgressPercentage (totalHours, timeSheetHours) {
      const percentage = (timeSheetHours / totalHours) * 100;
      const roundedPercentage = percentage.toFixed(2);
      return roundedPercentage;
    },
    openCashFlowModal () {
      this.cashFlowModal = true;
    },
    async getBudgetsPublicScheduleLinks () {
      this.loading = true;
      await this.$store.dispatch('projects/fetchBudgetsPublicScheduleLinks', {
        projectId: this.$route.params.projectId,
      }).then(response => {
        this.initGantt();
      }).catch(() => {
        this.loading = false;
      });
    },
    getRandomColor () {
      const colors = ['#71DCE0', '#91DA9C', '#A485E3'];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
    initGantt () {
      this.loading = true;
      this.tasks.data = [];
      this.tasks.links = [];
      gantt.config.columns = this.coloumns;
      if (this.showNoBudgetAlert === true) {
        this.loading = false;
        return;
      }
      if (!this.publicStatus) {
        this.tasks.links = this.ProjectScheduleLinks;
      } else {
        this.tasks.links = this.ProjectPublicScheduleLinks;
      }
      if (!this.publicStatus) {
        for (const job of this.jobList) {
          if (this.tasks.data.some(item => item.id === job.id)) {
            return;
          }
          this.tasks.data.push({
            id: job.id,
            text: job.name,
            start_date: new Date(job.planned_start_date),
            end_date: new Date(job.planned_end_date),
            progress: job.progress / 100 || 0,
            type: job.type === 'milestone' ? 'project' : job.type,
            parent: job.parent_job_id !== null ? job.parent_job_id : null,
            extId: job.external_id,
            jobType: job.job_type,
          });
        };
      } else {
        for (const job of this.publishedJobList) {
          if (this.tasks.data.some(item => item.id === job.id)) {
            return;
          }
          this.tasks.data.push({
            id: job.id,
            text: job.name,
            start_date: new Date(job.planned_start_date),
            end_date: new Date(job.planned_end_date),
            progress: job.progress / 100 || 0,
            type: job.type === 'milestone' ? 'project' : job.type,
            parent: job.parent_job_id !== null ? job.parent_job_id : 0,
            extId: job.external_id,
            jobType: job.job_type,
          });
        };
      }
      if (!this.publicStatus) {
        this.tasks.links = this.ProjectScheduleLinks;
      } else {
        this.tasks.links = this.ProjectPublicScheduleLinks;
      }
      gantt.templates.task_text = function (start, end, task) {
        return task.text + ' (' + Math.round(task.progress * 100) + '%)';
      };
      gantt.templates.scale_cell_class = function (date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
          return 'weekend';
        }
      };
      gantt.templates.timeline_cell_class = function (item, date) {
        if (date.getDay() === 0 || date.getDay() === 6) {
          return 'weekend';
        }
      };
      gantt.templates.date_grid = function (date) {
        return gantt.date.date_to_str('%d-%m-%Y')(date);
      };
      gantt.attachEvent('onTemplatesReady', function () {
        var toggle = document.createElement('i');
        toggle.className = 'fa fa-expand gantt-fullscreen';
        gantt.toggleIcon = toggle;
        gantt.$container.appendChild(toggle);
        toggle.onclick = function () {
          gantt.ext.fullscreen.toggle();
        };
      });
      gantt.attachEvent('onExpand', function () {
        var icon = gantt.toggleIcon;
        if (icon) {
          icon.className = icon.className.replace('fa-expand', 'fa-compress');
        }
      });
      gantt.attachEvent('onCollapse', function () {
        var icon = gantt.toggleIcon;
        if (icon) {
          icon.className = icon.className.replace('fa-compress', 'fa-expand');
        }
      });
      gantt.config.project_start = this.projectDetails.start_date !== null ? this.projectDetails.start_date : 'N/A';
      gantt.config.project_end = this.projectDetails.end_date !== null ? this.projectDetails.start_end : 'N/A';
      gantt.config.drag_progress = false;
      gantt.config.drag_move = true;
      gantt.config.drag_resize = false;
      gantt.config.details_on_dblclick = false;
      gantt.config.resize_dnd = true;
      gantt.config.date_format = '%d %M %Y';
      gantt.config.resize_rows = true;
      gantt.config.show_grid = true;
      gantt.config.resize_columns = true;
      gantt.config.grid_elastic_columns = true;
      gantt.config.smart_rendering = true;
      gantt.config.autosize = true;
      gantt.plugins({ tooltip: true, marker: true, fullscreen: true });
      gantt.init('gantt_here');
      gantt.clearAll();
      gantt.parse(this.tasks);
      gantt.render();
      // this.loading = false;

      gantt.event(window, 'mousemove', function (e) {
        if (!gantt.$container) {
          return;
        }
        var position = gantt.utils.dom.getRelativeEventPosition(e, gantt.$container);
        var ganttWidth = gantt.$container.offsetWidth;
        var ganttHeight = gantt.$container.offsetHeight;

        if (position.x < 0 ||
            position.y < 0 ||
            position.x > ganttWidth ||
            position.y > ganttHeight) {
          gantt.ext.tooltips.tooltip.hide();
        }
      });

      gantt.attachEvent('onAfterLinkAdd', (id, link) => {
        if (link) {
          const sourceTask = gantt.getTask(link.source);
          const targetTask = gantt.getTask(link.target);
          if (!sourceTask || !targetTask) {
            return;
          }
          link.source = sourceTask.id;
          link.target = targetTask.id;
          this.$store.dispatch('projects/storeProjectBudgetLinks', {
            ...link,
            source_id: sourceTask.extId,
            target_id: targetTask.extId,
          });
          this.isChanged = true;
          this.isSaved = false;
          this.isPublished = false;
          localStorage.setItem('isChanged', true);
          localStorage.setItem('isSaved', false);
          localStorage.setItem('isPublished', false);
        }
      });
      gantt.attachEvent('onAfterLinkDelete', (id) => {
        if (id) {
          this.$store.dispatch('projects/deleteStoredBudgetLink', id);
          this.isChanged = true;
          this.isSaved = false;
          this.isPublished = false;
          localStorage.setItem('isChanged', true);
          localStorage.setItem('isSaved', false);
          localStorage.setItem('isPublished', false);
        }
      });

      gantt.attachEvent('onTaskClick', (id, e) => {
        var task = gantt.getTask(id);
        this.jobTypes = task.jobType;
        if (e.target.classList.contains('gantt_task_content')) {
          this.jobId = task.extId;
          this.showEditJobDialog = true;
          e.preventDefault();
          return false;
        }
        return true;
      });
      this.loading = false;
    },
    async saveChanges () {
      this.loading = true;
      const budgetLinks = this.ProjectScheduleLinks;
      await this.$store.dispatch('projects/storeSavedProjectBudgetLinks', {
        projectId: this.$route.params.projectId,
        budgetLinks: {
          schedules: budgetLinks,
        },
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Project schedule saved successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.savedStatus = true;
        this.isChanged = false;
        this.isSaved = true;
        this.isPublished = false;
        localStorage.setItem('isChanged', false);
        localStorage.setItem('isSaved', true);
        localStorage.setItem('isPublished', false);
        this.loading = false;
        this.getProjectBudgets();
      }).catch(() => {
        this.loading = false;
      });
      // this.loading = false;
    },
    async publishSchedule () {
      this.loading = true;
      await this.$store.dispatch('projects/publishSchedule', {
        projectId: this.$route.params.projectId,
      }).then(response => {
        this.$store.dispatch('alert/onAlert', {
          message: 'Schedule Published successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
        this.getScheduleVersionsList();
        this.showPublishSchedule = true;
        this.savedStatus = false;
        this.isPublished = true;
        localStorage.setItem('isChanged', false);
        localStorage.setItem('isSaved', false);
        localStorage.setItem('isPublished', true);
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    editBudgetDetails () {
      this.showBudgetDetails = false;
      this.showEditDialog = true;
    },
    async getScheduleVersionsList () {
      this.loading = true;
      await this.$store.dispatch('jobs/fetchVersions', {
        projectId: this.$route.params.projectId,
      }).then(response => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    changeVersions () {
      this.loading = true;
      this.$store.dispatch('jobs/versionSetJobs', {
        jobs: this.versionDetails.jobs,
      }).then(response => {
        this.publicStatus = true;
        this.setSchedulelinks();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    setSchedulelinks () {
      this.loading = true;
      this.$store.dispatch('projects/setVersionScheduleLinks', {
        schedules: this.versionDetails.schedules,
      }).then(response => {
        this.initGantt();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
#gantt_here {
  height: 100vh;
  width: 100%;
}

.title-style {
  color: #37474F;
  margin-bottom: 25px;
}

.icon-style {
  color: #37474F !important;
  font-size: 45px !important;
}

.gantt_grid_scale,
.gantt_task_scale,
.gantt_task_vscroll {
  background-color: #FFFF;
}

.gantt_grid_scale,
.gantt_task_scale,
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_grid_scale .gantt_grid_head_cell {
  color: #37474F;
  border-bottom: 1px solid #37474F;
  font-weight: bold;
  font-size: 12px;
}

.gantt_row.odd {
  background-color: #fff;
}

.gantt_row:hover {
  background-color: #ECEFF1 !important;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #ECEFF1;
}

.gantt_task .gantt_task_scale .gantt_scale_cell {
  border-right: 1px solid #37474F;
}

.gantt_task_line {
  border-color: rgba(0, 0, 0, 0.35);
}

.gantt_task_line .gantt_task_progress {
  background-color: #FA9C9C;
}

.gantt_task_line {
  background-color: #F6DED1;
  box-shadow: none;
  border: none;
  color: #000;
  border: 1px solid #d58f67;
}

.gantt_task_line.gantt_task_content {
  color: #000 !important;
}

.gantt_task_progress {
  background: #92E3D7;
}

.gantt_task_line.gantt_project .gantt_task_progress {
  background-color: #92E3D7;
}

.gantt_task_line.gantt_project {
  background: #92E3D7;
}

.gantt_row.gantt_row_project {
  background-color: #ffffff;
  color: #fff;
  font-weight: bold;
}

.gantt_scale_line:nth-child(2) {
  background-color: #fff;
}

.task_color_red .gantt_task_bar {
  background-color: #00695C !important;
}

.task_color_green .gantt_task_bar {
  background-color: #01579B !important;
}

.task_color_blue .gantt_task_bar {
  background-color: #E65100 !important;
}

.task_color_yellow .gantt_task_bar {
  background-color: #C62828 !important;
}

.task_color_orange .gantt_task_bar {
  background-color: #37474F !important;
}

.task_color_orange .gantt_task_bar {
  background-color: #FF3700 !important;
}

.gantt_grid .gantt_grid_head_cell {
  border-right: 1px solid #37474F !important;
  border-bottom: 1px solid #37474F;
}

.no-file-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.project-list-card {
  box-shadow: none !important;
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.gantt_task_line {
  border-radius: 14px;
}

.gantt_task_line.gantt_milestone .gantt_task_content {
  border-radius: 3px !important;
  color: #000 !important;
}

.gantt_task_line.gantt_milestone {
  background-color: #81b9ff;
  border: 0 solid #3e536c;
}

.gantt_task_content {
  color: #000 !important;
}

.gantt_task_line.gantt_project {
  background-color: #DFF2EF;
  border: 1px solid #47b3a2;
}

.weekend {
  background-color: #e5e5f7;
}

.gantt_task_cell {
  border-right: 1px dashed #ccc !important;
}

.gantt_row,
.gantt_task_row {
  border: none;
}

.gantt_tree_icon.gantt_folder_open {
  background-image: url('../../../../assets/target.png');
  background-size: 18px;
}

.gantt_tree_icon.gantt_folder_closed {
  background-image: url('../../../../assets/target.png');
  background-size: 18px;
}

.gantt_tree_icon.gantt_file {
  background-image: url('../../../../assets/done.png');
  background-size: 18px;
}

.stay-tuned-msg {
  border: 1px solid #ccc;
  height: 300px;
  border-radius: 5px;
}

.active_btn {
  background: none !important;
  background-color: #ffffff !important;
  color: #000;
  box-shadow: none;
  border-radius: 0px;
  border-bottom: 2px solid red !important;
  padding: 10px 5px;
}

.normal_btn {
  background: none !important;
  background-color: #ffffff !important;
  color: #000;
  box-shadow: none;
  border-radius: 0px;
  padding: 10px 5px;
}

.version-select {
  width: 200px !important;
  margin-top: 2rem;
}
.single_day_task {
  width: 30px !important;
}
.gantt_thin_task {
  width: 50px !important;
}
</style>
